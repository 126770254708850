import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-eventlist',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.css']
})
export class EventlistComponent implements OnInit {

  events = [];
  pagination: any;
  closeResult: string;
  public showPagination = false;
  public selectedType: string = null;
  public selectedDistrict: string = null;
  public authState: any;
  public searchTerm = null;
  public submitted: any;
  public Editor = ClassicEditor;
  public loading = false;
  public errorMessage: any;
  public errorMessages: any;
  public filedErrorMessages: any;

  //Event Details
  public event_name: any;
  public event_type: any = 0;
  public event_date: any;
  public event_description: any;
  public event_content: any;
  public event_venue: any;
  public event_publisher: any;
  public event_email: any;
  public event_destrict: any = 0;
  public event_continuous: any = false;

  
  constructor(public apiService: ApiService, private modalService: NgbModal) { }

openModal(content, size) {
  this.modalService.open(content, {size: size, centered: true})
}

public updateSearchEvents(searchTerm: string) {
  this.searchTerm = searchTerm;
  if(searchTerm) {
  this.apiService.getEvents(1, 100, searchTerm).toPromise().then((response: any[]) => {
    let events = response['data'];
    this.events = events;
    this.showPagination = false;
  }).catch((error: any) => {
    window.alert('Error loading Events');
  });
  } else {
    this.loadEvents();
  }
}

public onEventChange(district) {

  if(district == 0) {
    district = null;
  }

  this.selectedDistrict = district;
  if(district ) {
    this.apiService.getEvents(1, 100, null, district).toPromise().then((response: any[]) => {
      let events = response['data'];
      this.events = events;
      this.showPagination = false;
    }).catch((error: any) => {
      window.alert('Error loading Events');
    });
  } else {
    this.loadEvents();
  }
  //console.log(district);


}

public onEventTypeChange(eventType) {
  if(eventType == 0) {
    eventType = null;
  }

  this.selectedType = eventType;

  if(eventType) {

    this.apiService.getEvents(1, 100, null, null, eventType).toPromise().then((response: any[]) => {
      let events = response['data'];
      this.events = events;
      this.showPagination = false;
    }).catch((error: any) => {
      window.alert('Error loading Events');
    });


   } else {
    this.loadEvents();
   } 
 }

  submitEvent() {
    let data = {data: {
      name: this.event_name,
      type: this.event_type,
      content: this.event_content,
      description: this.event_description,

      date: this.event_date,
      venue: this.event_venue,
      publisher: this.event_publisher,
      email: this.event_email,
      district: this.event_destrict,
      continuous: this.event_continuous,
      publishedAt: null
    }}


    this.apiService.submitEvent(data).toPromise().then((response: any[]) => {
      console.log("Event Created");
      this.submitted = true;
      this.clearForm();
    }).catch((error: any) => {
      //Status Code: 400
      this.submitted = false;
      window.alert('Error submitting the Event');
    });

  }

  clearForm() {
    this.event_name = '';
    this.event_type = 0;
    this.event_date = '';
    this.event_description = '';
    this.event_content = '';
    this.event_venue = '';
    this.event_publisher = '';
    this.event_email = '';
    this.event_destrict = 0;
    this.event_continuous = false;
  }


  ngOnInit(): void {

    this.authState = false;

    this.apiService.getAuthStatus().subscribe((data: any) => {
      this.authState = data;
    })
     
    this.loadEvents();
    
  }

  loadEvents(page: number = 1) {
    let limit = 6;
    this.apiService.getEvents(page, limit).toPromise().then((response: any[]) => {
      let events = response['data'];
      //console.log(events)
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      this.events = events;
      this.showPagination = true;
    }).catch((error: any) => {
      window.alert('Error loading Events');
    });

  }
   

}
