import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient:HttpClient) {}

   getGlobalSettings() {
     return this.httpClient.get('./assets/config/main_config.json');
   }
}
