import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-newslist',
  templateUrl: './newslist.component.html',
  styleUrls: ['./newslist.component.css']
})
export class NewslistComponent implements OnInit {

  news = [];
  pagination:any;

  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
    this.loadNews();
  }

  loadNews(page: number = 1) {
    let limit = 6;
    this.apiService.getNews(page, limit).toPromise().then((response: any[]) => {
      let news = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.news = news;
      console.log(this.news);
    }).catch((error: any) => {
      window.alert('Error loading News');
    });
  }

}
