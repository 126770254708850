import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-entrepreneurs',
  templateUrl: './entrepreneurs.component.html',
  styleUrls: ['./entrepreneurs.component.css']
})
export class EntrepreneursComponent implements OnInit {

  entrepreneurs: any;
  links: any;
  search: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadEntrepreneurs();
  }

  loadEntrepreneurs(page : number = 1) {
    this.apiService.getEntrepreneurs(page).toPromise().then(data => {
      this.entrepreneurs = data['data'];
      var links = data['links'];
      
      //Remove the first and last link from the array
      links.shift();
      links.pop();
      this.links = links;
      console.log(this.entrepreneurs);
      
      //this.pagination = data['meta']['pagination'];
      //console.log(this.entrepreneurs);
    }).catch(err => {
      window.alert('Error loading Entrepreneurs');
    });
  }

  searchEntrepreneurs($event) {
    let search = $event.target.value;
    console.log(search);
    if(search.length > 3) {
      this.apiService.searchEntrepreneurs(search).toPromise().then(data => {
        this.entrepreneurs = data;
        this.links = null;
      }).catch(err => {
        window.alert('Error searching Entrepreneurs');
      });
    }

    if(search.length == 0) {
      this.loadEntrepreneurs();
    }

  }

}
