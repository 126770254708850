import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-lmhome',
  templateUrl: './lmhome.component.html',
  styleUrls: ['./lmhome.component.css']
})
export class LmhomeComponent implements OnInit {

  lmCats = [];
  lmItems = [];
  auth: any;
  pagination: any;
  searchQuery: any;

  constructor(public apiService: ApiService, private helper: HelperService) { }

  ngOnInit(): void {
    this.loadLearningMaterials();
    this.loadCategories();
    this.authStatus();
    
  }

  

  loadCategories() {
    this.apiService.getLearningMaterialCategories().toPromise().then((response: any[]) => {
      let lmcats = response['data'];
      this.lmCats = lmcats;
    } ).catch((error: any) => {
      window.alert('Error loading Categories');
    } );
  }

  loadLearningMaterials(page: number = 1) {
    let limit = 6;
    this.apiService.getLearningMaterials(page, limit).toPromise().then((response: any[]) => {
      let lm = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.lmItems = lm;
    }).catch((error: any) => {
      window.alert('Error loading Learning Materials');
    });  
  }

  search($event) {
    this.apiService.searchLearningMaterials(this.searchQuery).toPromise().then((response: any[]) => {
      this.lmItems = response['data'];
    }).catch((error: any) => {
      window.alert('Error loading Learning Materials');
    } );
  }

  authStatus() {
    this.apiService.getAuthStatus().toPromise().then((response: any[]) => {
      this.auth = true;
    }).catch((error: any) => {
      this.auth = false;
    });
  }

}
