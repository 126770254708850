import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { SwiperModule } from 'swiper/angular';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoryComponent } from './story/story.component';
import { StorylistComponent } from './storylist/storylist.component';
import { LearningComponent } from './learning/learning.component';
import { MentoringComponent } from './mentoring/mentoring.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { NewslistComponent } from './newslist/newslist.component';
import { EventlistComponent } from './eventlist/eventlist.component';
import { NewsComponent } from './news/news.component';
import { EventComponent } from './event/event.component';
import { LmhomeComponent } from './lmhome/lmhome.component';
import { LitemComponent } from './litem/litem.component';
import { TfhomeComponent } from './tfhome/tfhome.component';
import { TfitemComponent } from './tfitem/tfitem.component';
import { RaitemComponent } from './raitem/raitem.component';
import { NetworkingComponent } from './networking/networking.component';
import { ContactComponent } from './contact/contact.component';
import { CohomeComponent } from './cohome/cohome.component';
import { CoursesComponent } from './courses/courses.component';
import { from } from 'rxjs';
import { EntrepreneursComponent } from './entrepreneurs/entrepreneurs.component';
import { LogoComponent } from './shared/logo/logo.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';
import { SocialShareComponent } from './shared/social-share/social-share.component';
import { PartnersComponent } from './partners/partners.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    StoryComponent,
    StorylistComponent,
    LearningComponent,
    MentoringComponent,
    AboutusComponent,
    NewslistComponent,
    EventlistComponent,
    NewsComponent,
    EventComponent,
    LmhomeComponent,
    LitemComponent,
    TfhomeComponent,
    TfitemComponent,
    RaitemComponent,
    NetworkingComponent,
    ContactComponent,
    CohomeComponent,
    CoursesComponent,
    EntrepreneursComponent,
    LogoComponent,
    LoaderComponent,
    SocialShareComponent,
    PartnersComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    SwiperModule,
    BrowserAnimationsModule,
    FormsModule,
    CKEditorModule,
    RouterModule,
    DataTablesModule,
    AppRoutingModule,
    HomeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
