import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-raitem',
  templateUrl: './raitem.component.html',
  styleUrls: ['./raitem.component.css']
})
export class RaitemComponent implements OnInit {

  articles: any;
  auth: any;
  searchQuery: string;
  pagination: any;


  constructor(private route: ActivatedRoute, private apiService: ApiService, private helper: HelperService) { }

  ngOnInit(): void {
    this.authStatus();
    this.loadArticles();
  }

  loadArticles(page: number = 1) {
    let limit = 6;
    this.apiService.getArticles(page, limit).toPromise().then((response: any[]) => {
      let articles = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.articles = articles;
    }).catch((error: any) => {
      window.alert('Error loading Articles');
    });  
  }

  search($event) {
    this.apiService.searchTools(this.searchQuery).toPromise().then((response: any[]) => {
      this.articles = response['data'];
    }).catch((error: any) => {
      window.alert('Error loading Articles');
    } );

  }

  authStatus() {
    this.apiService.getAuthStatus().toPromise().then((response: any[]) => {
      this.auth = true;
    }).catch((error: any) => {
      this.auth = false;
    });
  }

}
