import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiComService {

  //private REST_API_SERVER = "https://api-old.smeconnect.lk/";
  private REST_API_SERVER = "http://localhost:1337/api/";
  private REST_API_TEST = "https://api.smeconnect.lk/api/"

  constructor(private httpClient: HttpClient) {}

  public getNewsItems(page: number = 1) {
    return this.httpClient.get(this.REST_API_TEST+"news-items"+page);
  }



  public getSuccessStories(page: number = 1, limit: number = 10) {
    return this.httpClient.get(this.REST_API_TEST+"success-stories?pagination[page]="+page+"&pagination[limit]="+limit);
  }

  // public getSuccessStories(id=0) {
  //   if(id > 0) {
  //     return this.httpClient.get(this.REST_API_SERVER+"getSuccessStories.php?id="+id);
  //   }
  //   return this.httpClient.get(this.REST_API_SERVER+"getSuccessStories.php");
  // }

  public getNews(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getNews.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getNews.php");
  }

  public getEvents(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_TEST+"events/"+id);
    }
    return this.httpClient.get(this.REST_API_TEST+"events");
  }

  public getLmatsCat(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getLmatscat.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getLmatscat.php");
  }

  public getLmats(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getLmats.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getLmats.php");
  }

  public getLmatSearch(s) {
    return this.httpClient.get(this.REST_API_SERVER+"getLmats.php?q="+s);
  }

  public getTfCat(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getTfcat.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getTfcat.php");
  }

  public getTfs(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getTfs.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getTfs.php");
  }

  public getTfSearch(s) {
    return this.httpClient.get(this.REST_API_SERVER+"getTfs.php?q="+s);
  }

  public getRas(id=0) {
    if(id > 0) {
      return this.httpClient.get(this.REST_API_SERVER+"getRas.php?id="+id);
    }
    return this.httpClient.get(this.REST_API_SERVER+"getRas.php");
  }

  public getRasSearch(s) {
    return this.httpClient.get(this.REST_API_SERVER+"getRas.php?q="+s);
  }

  // public getCourses(id=0) {
  //   if(id > 0) {
  //     return this.httpClient.get(this.REST_API_SERVER+"getCourses.php?id="+id);
  //   }
  //   return this.httpClient.get(this.REST_API_SERVER+"getCourses.php");
  // }

  public getCourses() {
    return this.httpClient.get(this.REST_API_SERVER+"getCourses.php");
  }

  public getCourseSearch(s) {
    return this.httpClient.get(this.REST_API_SERVER+"getCourses.php?q="+s);
  }

  public getUsers() {
    return this.httpClient.get("http://128.199.181.107/portal/webservice/rest/server.php?wstoken=b4ab269653fa38414ac82ab942bc93d3&wsfunction=core_user_get_users&moodlewsrestformat=json&criteria[0][key]=deleted&criteria[0][value]=0");
  }

  public getLinks() {
    return this.httpClient.get(this.REST_API_SERVER+"getLinks.php");
  }

  public getAuthStatus() {
    return this.httpClient.get(this.REST_API_TEST+"user/data", { withCredentials: true });
  }

  public getEventSearch(s) {
    return this.httpClient.get(this.REST_API_TEST+"events/search/"+s);
  }

  public getEventsByDistrict(district) {
    return this.httpClient.get(this.REST_API_TEST+"events/filter/district/"+district);
  }

  public submitEventForm(data) {
    return this.httpClient.post(this.REST_API_TEST+"events", data, {headers: {'Accept':'application/json'}, withCredentials: true});
  }

  public submitMessageForm(data) {
    return this.httpClient.post(this.REST_API_TEST+"messages", data, {headers: {'Accept':'application/json'}});
  }

  public moodleLogin(data) {
    return this.httpClient.post("https://lms.smeconnect.lk/login/index.php", data, {headers: {'Accept':'*/*', 'Content-Type': 'application/x-www-form-urlencoded'}, withCredentials: true, responseType: 'text'})
  }

  
  //wefi_learning_materials

}
