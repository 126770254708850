import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
  selector: 'app-networking',
  templateUrl: './networking.component.html',
  styleUrls: ['./networking.component.css']
})
export class NetworkingComponent implements OnInit {
  userData: [];

  entrepreneurs: any;
  mentors: any;

  mOptions: SwiperOptions = {
      
    slidesPerView: 4,
    autoplay: false,
    centeredSlides: true,
    effect: 'coverflow',
    //centeredSlides: true,
    spaceBetween: 0,
    grabCursor: true,

    navigation: {
      prevEl: ".swiper-prev-story",
      nextEl: ".swiper-next-story"
    },
    
    breakpoints: {
      768: {
        slidesPerView: 4
      },
      576: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    }
    
}

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.randomEntrepreneurs();
    //this.randomMentors();
  }

  randomEntrepreneurs() {
    this.apiService.getRandomEntrepreneurs().toPromise().then(data => {
      this.entrepreneurs = data;
    }).catch(err => {
      window.alert('Error loading Entrepreneurs');
    });
  }

  randomMentors() {
    this.apiService.getRandomMentors().toPromise().then(data => {
      this.mentors = data;
    }).catch(err => {
      window.alert('Error loading Mentors');
    });
  }
  
  getFormatedLang(lang) {
    var flang = "";
    switch(lang) {
      case "en":
        flang = "EN";
        break;
      case "si":
        flang = "සිං";
        break;
      case "ta":
        flang = "தமி";
        break;
    }
    return flang;
  }

}
