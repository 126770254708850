import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { StoryComponent } from './story/story.component';
import { StorylistComponent } from './storylist/storylist.component';
import { LandingComponent } from './landing/landing.component';
import {CoursesComponent} from './courses/courses.component';
import { LearningComponent } from './learning/learning.component';
import { MentoringComponent } from './mentoring/mentoring.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { NewslistComponent } from './newslist/newslist.component';
import { EventlistComponent } from './eventlist/eventlist.component';
import {NewsComponent} from './news/news.component';
import {EventComponent} from './event/event.component';
import {TfhomeComponent} from './tfhome/tfhome.component';
import {LmhomeComponent} from './lmhome/lmhome.component';
import {LitemComponent} from './litem/litem.component';
import {TfitemComponent} from './tfitem/tfitem.component';
import {RaitemComponent} from './raitem/raitem.component';
import {NetworkingComponent} from './networking/networking.component';
import { EntrepreneursComponent } from './entrepreneurs/entrepreneurs.component';
import {PartnersComponent} from './partners/partners.component';


const routes: Routes =[
    // { path: 'home',             component: HomeComponent },
    // { path: 'user-profile',     component: ProfileComponent },
    // { path: 'register',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    // { path: 'login',          component: LoginComponent },
    { path: 'stories',          component: StorylistComponent },
    { path: 'story/:id',          component: StoryComponent },
    { path: 'learning',            component: LearningComponent},
    { path: 'networking',            component: NetworkingComponent},
    { path: 'mentoring',           component: MentoringComponent}, 
    { path: 'about-us',           component: AboutusComponent}, 
    { path: 'courses',            component: CoursesComponent},
    { path: 'news',           component: NewslistComponent}, 
    { path: 'events',           component: EventlistComponent}, 
    { path: 'news/:id',          component: NewsComponent },
    { path: 'event/:id',               component: EventComponent},
    { path: 'learning-materials',               component: LmhomeComponent},
    { path: 'learning-material/:id',               component: LitemComponent},
    { path: 'tools-formats',               component: TfhomeComponent},
    { path: 'tools-format/:id',               component: TfitemComponent},
    { path: 'reports-articles',               component: RaitemComponent},
    { path: 'entrepreneurs',              component: EntrepreneursComponent},
    { path: 'our-partners',  component: PartnersComponent},
    { path: '',               component: LandingComponent},

    // { path: '', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
