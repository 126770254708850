import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  
  private sitemap = [

    { title: "SME Connect", url : "/#/", 
      items: [
        { name: "Home", url : "/#/"},
        { name: "About Us", url : "/#/about-us"},
        { name: "News", url : "/#/news"},
        { name: "Sign Up", url : "https://lms.smeconnect.lk/login/signup.php"},
      ],

    },
    { title: 'Learning', url: 'https://smeconnect.lk/#/learning',
      items: [
        { name: 'Learning Materials', url: '/#/learning-materials' },
        { name: 'Tools and Formats', url: '/#/tools-formats' },
        { name: 'Success Stories', url: '/#/stories' },
        { name: 'Articles and Reports', url: '/#/reports-articles' },
        { name: 'Online Courses', url: '/#/courses' },
      ]
    },
    { title: 'Mentoring', url: 'https://smeconnect.lk/#/mentoring',
        items: [
        { name: 'Pool of Mentors', url: 'https://mentoring.smeconnect.lk/#/mentors' },
        { name: 'Dashboards', url: 'https://mentoring.smeconnect.lk/#/index' },
        { name: 'Registration', url: 'https://mentoring.smeconnect.lk/#/login?redirect=mentor' },
      ]
    },
    { title: 'Networking', url: '/#/networking',
        items: [
        { name: 'Events and Programs', url: '/#/events' },
        { name: 'Blog', url: 'https://blog.smeconnect.lk/' },
        { name: 'Forums', url: 'https://lms.smeconnect.lk/mod/forum/view.php?id=1' },
        { name: 'Meet our Entrepreneurs', url: '/#/entrepreneurs' },
      ]
    },
  ] 


  constructor() { }

  public getSitemap() {
    return this.sitemap;
  }
}
