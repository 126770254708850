import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { Router } from '@angular/router';

import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  

  public doners: any;
  public partners: any;
  public financialPartners: any;
  public knowledgePartners: any;
  public otherPartners: any;
  public isPartnerLoading: boolean = true;

  //Swiper Options for Logos Carousel
  options: SwiperOptions = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 25,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: false,
    },
    slidesPerView: 3,
    spaceBetween: 0,
    grabCursor: true,
    breakpoints: {
      768: {
        slidesPerView: 3
      },
      576: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    }
  }




  constructor(private apiService: ApiService, private router: Router ) { }

  ngOnInit(): void {
    this.fetchDonors();
    this.fetchPartners();
  
  }

  fetchDonors() {
    this.apiService.getDonors().toPromise().then((data) => {
      this.doners = data['data'];
    }).catch((err) => {
      console.log(err);
    });

  }

  getPath(){
    return this.router.url;
  }

  fetchPartners() {
    Promise.all([
      this.apiService.getFinancialPartners().toPromise(),
      this.apiService.getKnowledgePartners().toPromise(),
      this.apiService.getOtherPartners().toPromise() // Adjust this as needed for other partners
    ])
      .then(([financialPartnersData, knowledgePartnersData, otherPartnersData]) => {
        this.financialPartners = financialPartnersData['data'];
        this.knowledgePartners = knowledgePartnersData['data'];
        this.otherPartners = otherPartnersData['data'];

        this.partners = this.financialPartners.concat(this.knowledgePartners, this.otherPartners);
        this.isPartnerLoading = false;
        // You can now proceed with any further logic as all data is loaded
      })
      .catch((err) => {
        this.isPartnerLoading = false;
        alert("Error loading partners.")
        console.log(err);
      });

  }

}
