import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';



import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  public contactErrorMessageMain: any;
  public contactErrorMessages: any;
  public submittedContact: any;

  stories = [];
  news = [];
  events = [];

  sOptions: SwiperOptions = {
      
      slidesPerView: 4,
      autoplay: false,
      //centeredSlides: true,
      spaceBetween: 0,
      grabCursor: true,

      navigation: {
        prevEl: ".swiper-prev-story",
        nextEl: ".swiper-next-story"
      },
      
      breakpoints: {
        768: {
          slidesPerView: 4
        },
        576: {
          slidesPerView: 2
        },
        300: {
          slidesPerView: 1
        }
      }
      
  }

  options2: SwiperOptions = {
    loop: true,
    speed: 1000,
  }

  constructor(private apiService: ApiService) { }

  
  public onMessageSubmit(data){ 
    console.log(data);
    let message = {"data": data};
    this.apiService.createMessage(message).toPromise().then((response: any) => {
      this.submittedContact = true;
    }).catch((error: any) => {
      window.alert(error['error']['message']);
    });
  }

  ngOnInit() {
    this.loadSuccessStories();
    this.loadNewsItems();
    this.loadEvents();
    //this.simpleAlert();
    // this.apiService.getPopups().toPromise().then((response: any) => {
    //   let popups = response['data'];

    //   //console.log(popups[0]['Image']['data']['formats']['medium']['url']);
    //   if(popups.length > 0) {

    //     let popup = popups[0];

    //     console.log(popup['attributes']['Image']['data']['attributes']['formats']['medium']['url']);
    //     Swal.fire({
          
    //       title: 'Slideshow',
    //       html: `
    //         <div class="slideshow-container">
    //           <div class="mySlides fade">
    //             <img src="https://admin.smeconnect.lk/${popup['attributes']['Image']['data']['attributes']['formats']['medium']['url']}" style="width:100%">
    //           </div>
    //           <div class="mySlides fade">
    //             <img src="https://via.placeholder.com/300?text=Slide+2" style="width:100%">
    //           </div>
    //           <div class="mySlides fade">
    //             <img src="https://via.placeholder.com/300?text=Slide+3" style="width:100%">
    //           </div>
    //         </div>
    //       `,
    //       didOpen: () => {
    //         let slideIndex = 0;
    //         const slides = document.querySelectorAll('.mySlides');
            
    //         function showSlides() {
    //           slides.forEach(slide => ((slide as HTMLElement).style.display = 'none'));
    //           slideIndex++;
    //           if (slideIndex > slides.length) {
    //             slideIndex = 1;
    //           }
    //           (slides[slideIndex - 1] as HTMLElement).style.display = 'block';
    //           setTimeout(showSlides, 3000); // Change slide every 3 seconds
    //         }
            
    //         showSlides();
    //       },
    //       showConfirmButton: false,
    //       width: 600
    //     });
    //   }
    // }).catch((error: any) => {
    //   console.log('Error loading Popups', error);
    // });
  }

  loadSuccessStories() {
    this.apiService.getSuccessStories(1, 6).toPromise().then((response: any[]) => {
      let stories = response['data'];
      this.stories = stories;
    }).catch((error: any) => {
      window.alert('Error loading Success Stories');
    });
  }

  loadNewsItems() {
    this.apiService.getNews(1, 6).toPromise().then((response: any[]) => {
      let news = response['data'];
      // Get the first 4 news
      this.news = news.slice(0, 4);
      
    }).catch((error: any) => {
      window.alert('Error loading News Items');
    });
  }

  loadNews() {
    this.apiService.getNews().toPromise().then((response: any[]) => {
      //Get the first 4 news
      let news = response['data'].slice(0, 4);

      this.news = news;
    }).catch((error: any) => {
      window.alert('Error loading News');
    });
  }

  loadEvents() {
    this.apiService.getEvents().toPromise().then((response: any[]) => {
      //Get the first 4 events
      let events = response['data'].slice(0, 4);

      this.events = events;
      console.log(this.events)
    }).catch((error: any) => {
      window.alert('Error loading Events');
    });
  }

  simpleAlert() {
    Swal.fire({
      imageUrl: './assets/img/popup/E-flyer_Online_Sin_published.jpg',
      imageAlt: 'Advert image',
      showCloseButton: true,
      confirmButtonText: "Know More"
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = 'https://www.pwc.com/lk/en/assets/ida/RCT-Advert-Online-Sin.pdf';
      } 
    })

  }

}
