import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../_services/api.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnDestroy {

  id: number;
  private sub: any;
  newsData: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];

      this.apiService.getNewsItem(this.id).toPromise().then((response: any[]) => {
        this.newsData = response['data'];
      }).catch((error: any) => {
        window.alert('Error loading News Item');
        this.router.navigate(['/news']);
      });
   });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
