import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  private sub: any;
  courses: [];
  pagination: any;
  searchq: string;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadCourses();
  }

  loadCourses(page: number = 1) {
    let limit = 6;
    this.apiService.getCourses(page, limit).toPromise().then((response: any[]) => {
      let courses = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.courses = courses;
    }).catch((error: any) => {
      window.alert('Error loading Courses');
    });
  }

  search($event) {
    this.apiService.getCourses(1, 100, this.searchq).toPromise().then((response: any[]) => {
      let courses = response['data'];
      this.courses = courses;
    }
    ).catch((error: any) => {
      window.alert('Error loading Courses');
    });
  }

}
