import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Location, PopStateEvent } from '@angular/common';
import {SettingsService} from '../../settings.service';
import { ApiComService } from '../../api-com.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public settings;
    public isCollapsed = true;
    public dropSubServices = false;
    public authState;
    private lastPoppedUrl: string;
    public loginInvalidMessage = false;
    public loginProcess = false;
    private yScrollStack: number[] = [];

    constructor(public location: Location, private router: Router, public translate: TranslateService, public settingsService: SettingsService, private apiService: ApiComService, private modalService: NgbModal) {
        this.settingsService.getGlobalSettings().subscribe((data: any[]) => {
            this.settings = data;
            // console.log(this.advancedlang)
            
          });
        translate.addLangs(['en', 'sin']);
        translate.setDefaultLang('en');

        this.apiService.getAuthStatus().subscribe((authData: any) => {
            this.authState = authData['data'];
        })

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|sin/) ? browserLang : 'en')
    }

    ngOnInit() {
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }

    openModal(content, size) {
        this.modalService.open(content, {size: size, centered: true})
      }

    moodleLogin(data) {
        // Login request

        // Starting Login process.
        this.loginProcess = true;

        var encodedBody = `username=`+encodeURIComponent(data.username)+`&password=`+encodeURIComponent(data.password)

        this.apiService.moodleLogin(encodedBody).subscribe((data: any) => {
            this.apiService.getAuthStatus().subscribe((authData: any) => {
                if(authData['data']['id'] > 0) {
                    // Successful login.
                    this.authState = authData['data'];
                    Swal.fire({
                        icon: 'success',
                        title: 'Login Success',
                        text: 'Welcome back! '+ authData['data']['firstname'],
                        showConfirmButton: false,
                        timer: 2500
                      })
                      this.loginProcess = false;
                      this.modalService.dismissAll('force');
                } 
            }, (error) => {
                if(error.status === 401) {
                    // Invalid login.
                    this.loginInvalidMessage = true;
                } 
                this.loginProcess = false;
            })
        }, (error) => {
            console.log(error);
        })

    
        
    }

    showSubServices() {
        this.dropSubServices = !this.dropSubServices;
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }

    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    
}
