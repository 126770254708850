import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private CMS_API = "https://admin.smeconnect.lk/api/";
  private REST_API = "https://api.smeconnect.lk/api/";

  constructor(private httpClient: HttpClient) {}


  // Success Stories
  public getSuccessStories(page: number = 1, limit: number = 25) {
    return this.httpClient.get(this.CMS_API+"success-stories?pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public getSuccessStory(id) {
    return this.httpClient.get(this.CMS_API+"success-stories/"+id+"?populate=*");
  }

  public getPopups() {
    return this.httpClient.get(this.CMS_API+"popups?populate=*");
  }

  // News Items
  public getNews(page: number = 1, limit: number = 25) {
    return this.httpClient.get(this.CMS_API+"news-items?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public getNewsItem(id: number) {
    return this.httpClient.get(this.CMS_API+"news-items/"+id+"?populate=*");
  }

  // Learning Materials
  public getLearningMaterialCategories(catId: number = null) {
    if(catId) {
      return this.httpClient.get(this.CMS_API+"learning-material-categories/"+catId+"?populate=*");
    } else {
      return this.httpClient.get(this.CMS_API+"learning-material-categories?pagination[pageSize]=100&populate=*");
    }
  }

  public getLearningMaterialsByCategory(catId: number = 1, page: number = 1) {
      return this.httpClient.get(this.CMS_API+"learning-materials?filters[learning_material_category][id][$eq]="+catId+"&sort[0]=id:desc&&pagination[page]="+page+"&populate=*");
  }

  public getLearningMaterials(page: number = 1, limit: number = 10) {
    return this.httpClient.get(this.CMS_API+"learning-materials?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public searchLearningMaterials(q: string) {
    return this.httpClient.get(this.CMS_API+"learning-materials?sort[0]=id:desc&filters[name][$containsi]="+q+"&pagination[pageSize]=100&populate=*");
  }

  // Tools and Formats
  public getToolCategories(catId: number = null) {
    if(catId) {
      return this.httpClient.get(this.CMS_API+"tool-categories/"+catId+"?populate=*");
    } else {
      return this.httpClient.get(this.CMS_API+"tool-categories?pagination[pageSize]=100&populate=*");
    }
  }

  public getToolsByCategory(catId: number = 1, page: number = 1) {
      return this.httpClient.get(this.CMS_API+"tools?sort[0]=id:desc&filters[tool_category][id][$eq]="+catId+"&pagination[page]="+page+"&populate=*");
  }

  public getTools(page: number = 1, limit: number = 10) {
    return this.httpClient.get(this.CMS_API+"tools?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public searchTools(q: string) {
    return this.httpClient.get(this.CMS_API+"tools?sort[0]=id:desc&filters[name][$containsi]="+q+"&pagination[pageSize]=100&populate=*");
  }

  // Articles
  public getArticles(page: number = 1, limit: number = 10) {
    return this.httpClient.get(this.CMS_API+"articles?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public searchArticles(q: string) {
    return this.httpClient.get(this.CMS_API+"articles?sort[0]=id:desc&filters[name][$containsi]="+q+"&pagination[pageSize]=100&populate=*");
  }

  // Useful Links
  public getLinks(page: number = 1, limit: number = 25) {
    return this.httpClient.get(this.CMS_API+"useful-links?pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  // Events
  public getEvents(page: number = 1, limit: number = 25, query: string = null, district: string = null, type: string = null) {
    if(query) {
      return this.httpClient.get(this.CMS_API+"events?filters[name][$containsi]="+query+"&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    } else if(district) {
      return this.httpClient.get(this.CMS_API+"events?filters[district][$eq]="+district+"&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    } else if(type) {
      console.log(type);
      return this.httpClient.get(this.CMS_API+"events?filters[type][$eq]="+type+"&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    } else {
      return this.httpClient.get(this.CMS_API+"events?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    }
  }

  public getEvent(id: number) {
    return this.httpClient.get(this.CMS_API+"events/"+id+"?populate=*");
  }

  public submitEvent(data) {
    let jsonData = JSON.stringify(data);
    return this.httpClient.post(this.CMS_API+"events", jsonData, {headers: {'Content-Type': 'application/json'}});
  }

  public getCourses(page: number = 1, limit: number = 25, query: string = null) {
    if(query) {
      return this.httpClient.get(this.CMS_API+"courses?sort[0]=id:desc&filters[name][$containsi]="+query+"&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    } else {
      return this.httpClient.get(this.CMS_API+"courses?sort[0]=id:desc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
    }
  }

  // Entrepreneurs
  public getEntrepreneurs(page: number = 1 ) { 
     return this.httpClient.get(this.REST_API+"entrepreneurs?page="+page, {headers: {'Content-Type': 'application/json'}});
  }

  public searchEntrepreneurs(name: string) { 
      return this.httpClient.get(this.REST_API+"entrepreneurs/search?name="+name, {headers: {'Content-Type': 'application/json'}});
  }

  public getRandomEntrepreneurs() {
    return this.httpClient.get(this.REST_API+"entrepreneurs/random", {headers: {'Content-Type': 'application/json'}});
  }

  public getRandomMentors() {
    return this.httpClient.get(this.REST_API+"mentor/random", {headers: {'Content-Type': 'application/json'}});
  }

  // Messages
  public createMessage(message: any) {
    return this.httpClient.post(this.CMS_API+"messages", message, {headers: {'Content-Type': 'application/json'}});
  }

  // Partners no Sort by ORDER
  public getPartners(page: number = 1, limit: number = 50) {
    return this.httpClient.get(this.CMS_API+"partners?filters[type][$eq]=Partner&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public getFinancialPartners(page: number = 1, limit: number = 50 ) {
    return this.httpClient.get(this.CMS_API+"partners?filters[type][$eq]=Partner - Financial&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  public getKnowledgePartners(page: number = 1, limit: number = 50 ) {
    return this.httpClient.get(this.CMS_API+"partners?filters[type][$eq]=Partner - Knowledge&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }


  public getPartnerByName( name: string) {
    return this.httpClient.get(this.CMS_API+"partners?filters[name][$eq]="+name+"&populate=*");

  }

  public getOtherPartners (page: number = 1, limit: number = 50 ) {
    return this.httpClient.get(this.CMS_API+"partners?filters[type][$eq]=Partner - Other&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  // Donors 
  public getDonors(page: number = 1, limit: number = 25) {
    return this.httpClient.get(this.CMS_API+"partners?filters[type][$eq]=Powered&sort[0]=order:asc&pagination[page]="+page+"&pagination[pageSize]="+limit+"&populate=*");
  }

  // Authentication
  public getAuthStatus() {
    return this.httpClient.get(this.REST_API+"user/data", { withCredentials: true });
  }

  public moodleLogin(data) {
    return this.httpClient.post("https://lms.smeconnect.lk/login/index.php", data, {headers: {'Accept':'*/*', 'Content-Type': 'application/x-www-form-urlencoded'}, withCredentials: true, responseType: 'text'})
  }

  public submitMessageForm(data) {
    return this.httpClient.post(this.REST_API+"messages", data, {headers: {'Accept':'application/json'}});
  }


 

  
  //wefi_learning_materials

}
 