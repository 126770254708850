import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { cardAnimation } from '../animations';

@Component({
  selector: 'app-storylist',
  animations: [cardAnimation],
  templateUrl: './storylist.component.html',
  styleUrls: ['./storylist.component.css']
})
export class StorylistComponent implements OnInit {

  stories = [];
  pagination:any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadSuccessStories();
  }
  

  loadSuccessStories(page: number = 1) {
    let limit = 6;
    this.apiService.getSuccessStories(page, limit).toPromise().then((response: any[]) => {
      let stories = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      console.log(this.pagination);
      this.stories = stories;
    }).catch((error: any) => {
      window.alert('Error loading Success Stories');
    });
  }

  

}
