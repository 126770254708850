import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-mentoring',
  templateUrl: './mentoring.component.html',
  styleUrls: ['./mentoring.component.css']
})
export class MentoringComponent implements OnInit {

  public mentors: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.randomMentors();
  }

  randomMentors() {
    this.apiService.getRandomMentors().toPromise().then(data => {
      this.mentors = data;
    }).catch(err => {
      window.alert('Error loading Mentors');
    });
  }
  

}
