import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SitemapService } from '../../_services/sitemap.service';
import { ApiService } from '../../_services/api.service';

import { LoginComponent } from 'src/app/login/login.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();

    public sitemap: any;
    //public doners: any;

    constructor(private router: Router, private sitemapService: SitemapService, private apiService: ApiService ) {}

    ngOnInit() {
      this.sitemap = this.sitemapService.getSitemap();
      //this.fetchDonors();
    }


    getPath(){
      return this.router.url;
    }

    // fetchDonors() {
    //   this.apiService.getDonors().toPromise().then((data) => {
    //     this.doners = data['data'];
    //     //Getting the first 8 partners
    //     this.doners = this.doners.slice(0, 8);

    //   }).catch((err) => {
    //     console.log(err);
    //   });

    // }
}
