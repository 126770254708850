
import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit, AfterViewInit {

  @ViewChild('iframeContent', { static: false }) iframe: ElementRef;

  constructor( private apiService: ApiService, private activatedRoute: ActivatedRoute, private domSanitizer: DomSanitizer ) { }

  public partners: any = null;

  public financialPartners: any = null;
  public knowledgePartners: any = null;
  public otherPartners: any = null;
  public isPartnerLoading: boolean = false;
  public selectedPartner: object = null;

  public iframeReady: boolean = false;


  ngAfterViewInit(): void {

    const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;

  }
  

  ngOnInit(): void {


    
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['partner']) {
        this.selectPartner(params['partner'])
      }
    });

    this.isPartnerLoading = true;
    Promise.all([
      this.apiService.getFinancialPartners().toPromise(),
      this.apiService.getKnowledgePartners().toPromise(),
      this.apiService.getOtherPartners().toPromise() // Adjust this as needed for other partners
    ])
      .then(([financialPartnersData, knowledgePartnersData, otherPartnersData]) => {
        this.financialPartners = financialPartnersData['data'];
        this.knowledgePartners = knowledgePartnersData['data'];
        this.otherPartners = otherPartnersData['data'];
        this.isPartnerLoading = false;
        // You can now proceed with any further logic as all data is loaded
      })
      .catch((err) => {
        this.isPartnerLoading = false;
        alert("Error loading partners.")
        console.log(err);
      });

    
  }

  iframeUrl(url) : SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  } 
  iframeOnLoad(e) {
    this.iframeReady = true;
  }


  public selectPartner(name: string) {
    this.apiService.getPartnerByName(name).toPromise().then((data) => {
      this.selectedPartner = data['data'][0]['attributes'];
      setTimeout(()=>{
        this.setIframeSource(this.selectedPartner['iframe_page_src']);
      }, 1000)
     // this.iframeReady = false;
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    }).catch((err) => {
      alert("Requested partner detials not found.")
      console.log(err)
    })
  }

   clearSelectedPartner() {
    //console.log("clicked")
    this.selectedPartner = null;

    window.location.href = '#/our-partners'
  }

  getTypeDisplayString(type: string) {

    var displayString = null;
    switch (type) {
      case 'Partner - Financial':
        displayString = 'Financial Partner'
        break;
      case 'Partner - Knowledge':
        displayString = 'Knowledge / Mentoring Partner'
        break;
      default:
        displayString = 'Partner'
        break;
    }
    return displayString;
  }


  setIframeSource(url: string): void {
    this.iframeReady = false;
    const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;

    iframeElement

    // Set the iframe's src attribute to the provided URL
    iframeElement.src = url;
    iframeElement.style.height='100vh';
    iframeElement.style.width = '100%';

    // Wait for the iframe to fully load
    iframeElement.onload = () => {
      console.log('Iframe content loaded successfully!');
      this.iframeReady = true;
      // Additional logic can be placed here
    };
  }

  
}
