import { Component, OnInit } from '@angular/core';
import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import { ApiService } from '../_services/api.service';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.css']
})
export class LearningComponent implements OnInit {

  links: [];
  pagination: any;


  linksSliderOpt: SwiperOptions = {
      
    slidesPerView: 2,
    autoplay: false,
    //centeredSlides: true,
    spaceBetween: 0,
    grabCursor: true,

    navigation: {
      nextEl: '.swiper-next-custom',
      prevEl: '.swiper-prev-custom'
    },
    
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      576: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    }
    
}


  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
    this.loadLinks();
  }

  loadLinks(page: number = 1) {
    let limit = 6;
    this.apiService.getLinks(page, limit).toPromise().then((response: any[]) => {
      let links = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      this.links = links;
    }).catch((error: any) => {
      window.alert('Error loading News');
    });
  }

}
